import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import axios from 'axios'
import Linkify from 'react-linkify'
import styled from 'styled-components'
import { Box, Flex, DesktopContainer, MobileContainer } from '../components/ui/BuildingBlocks'
import Button from '../components/ui/Button'
import Spinner from '../components/ui/Spinner'
import { colors, smallBoxShadow } from '../components/ui/helpers.js'
import { urlToString } from '../components/ui/formatters.js'
import countryToIso2 from '../../constants/countryToIso2'
import { translate, activeCountries, countryTranslate } from '../../constants/translations'
import { H1, H2, H3, H4, SeoH2, SeoH4, Span, Text, LinkSpan } from '../components/ui/Font.js'
import OnlineIcon from '../components/ui/OnlineIcon'
import Image from '../components/ui/Image'
import Layout from '../components/layout'
import Helmet from '../components/Helmet'
import Footer from '../components/Footer'
import telephones from '../../constants/telephones'

const Shape1 = styled(Box)`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 30vw;
  height: 30vw;
  background-color: rgba(255, 255, 255, 0.02);
  border-radius: 300px 300px 300px 0px;
`

const Shape4 = styled(Box)`
  position: absolute;
  left: 0;
  bottom: 30vw;
  width: 30vw;
  height: 30vw;
  background-color: rgba(255, 255, 255, 0.02);
  border-radius: 0px 300px 300px 0px;
`

const Shape2 = styled(Box)`
  position: absolute;
  left: 30vw;
  top: 0;
  width: 35vw;
  height: 85vh;
  background-color: rgba(255, 255, 255, 0.02);
  border-radius: 0px 0px 300px 0px;
  z-index: 1;
`

const Shape3 = styled(Box)`
  position: absolute;
  left: 65vw;
  top: 20vh;
  width: 30vw;
  height: 80vh;
  background-color: rgba(255, 255, 255, 0.02);
  border-radius: 300px 0px 0px 0px;
  z-index: 1;
`

const Left = styled(Box)`
  z-index: 1;
  padding-left: 200px;
  @media (max-width: 1250px) {
    padding-left: 100px;
    margin-top: 150px;
  }
  @media (max-width: 1000px) {
    padding-left: 24px;
    margin-top: 150px;
  }
  @media (max-width: 500px) {
    margin-top: 100px;
    padding-right: 24px;
  }
  @media (max-width: 330px) {
    margin-top: 50px;

    padding-left: 16px;
    padding-right: 16px;
  }
`

const NumberGrid = styled(Flex)`
  flex-wrap: wrap;
  justify-content: center;
  @media (max-width: 450px) {
    padding-left: 16px;
    padding-right: 16px;
    a {
      width: 100%;
    }
  }
`

const TelephoneContainer = styled(Flex)`
  background-color: white;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  flex-direction: column;
  margin: 16px;
  width: 280px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);

  &:hover {
    -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
  }
  @media (max-width: 500px) {
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
  }
`

const ResponsivePaddingFlex = styled(Flex)`
  padding-left: 200px;
  padding-right: 200px;
  @media (max-width: 1250px) {
    padding-left: 100px;
    padding-right: 100px;
  }
  @media (max-width: 1000px) {
    padding-left: 24px;
    padding-right: 24px;
  }
  @media (max-width: 330px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`

const Header = styled(H1)`
  color: white;
  font-size: 60px;
  line-height: 70px;
  margin-bottom: 32px;
  @media (max-width: 500px) {
    font-size: 40px;
    line-height: 50px;
  }
`

const SubHeader = styled(H2)`
  color: white;
  font-size: 30px;
  line-height: 40px;
  font-weight: 300;
  @media (max-width: 700px) {
    font-size: 24px;
    line-height: 32px;
  }
`

const CountryHeader = styled(H3)`
  font-size: 30px;
  line-height: 40px;
  font-weight: 300;
  @media (max-width: 700px) {
    font-size: 24px;
    line-height: 32px;
  }
`

const FlagImage = styled.img`
  margin-bottom: 8px;
  border-radius: 8px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
`
const CounterContainer = styled(Flex)`
  width: 20px;
  height: 20px;
  background-color: ${colors.darkGrey};
  color: white;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 12px;
  left: 36px;
  @media (max-width: 700px) {
    top: 8px;
    right: 18px;
  }
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
`

const Counter = ({ count }) => {
  return (
    <CounterContainer>
      <Span color="white" fontSize="12px" bold>
        {count}
      </Span>
    </CounterContainer>
  )
}

const CountryContainer = styled(Flex)`
  max-height: 120px;
  height: 70px;
  width: 200px;
  background-color: white;
  border-radius: 24px;

  cursor: pointer;
  position: relative;
  flex-direction: row;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 24px;
  padding-left: 16px;
  padding-right: 16px;
  margin: 24px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: ${colors.orange};
  }
  @media (max-width: 700px) {
    margin: 8px;
    width: 90vw;
  }
  @media (max-width: 330px) {
    margin: 8px;
  }
`

const Flag = ({ iso2 }) => {
  return <FlagImage alt={`${iso2} flag`} src={`/country-flag/${iso2}.svg`} width="30px" height="30px" />
}

const Grid = styled(Flex)`
  flex-wrap: wrap;
  @media (max-width: 700px) {
    justify-content: center;
  }
`

const IconContainer = styled(Box)`
  width: 100px;
  height: 100px;
  border-radius: 24px;
  padding: 22px;
  margin-bottom: 32px;
`

const MockupStyler = styled(Flex)`
  .phoneMockup {
    position: absolute;
    top: 0;
    right: -200px;
    @media (max-width: 900px) {
      right: -100px;
    }
    @media (max-width: 700px) {
      right: -50px;
    }
    @media (max-width: 500px) {
      right: -100px;
    }
  }
  .desktopMockup {
    position: absolute;
    top: 0;
    right: -100px;
    @media (max-width: 900px) {
      right: 100px;
    }
    @media (max-width: 700px) {
      right: 0px;
    }
    @media (max-width: 500px) {
      display: none;
    }
  }
`

const NumberContainer = styled(Flex)`
  width: 100px;
  height: 100px;
  min-width: 100px;
  border-radius: 24px;
  padding: 22px;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin-right: 16px;
`

const NumberCount = ({ number }) => {
  return (
    <NumberContainer>
      <Text fontSize="40px" fontWeight="500" color={colors.purple}>
        {number}
      </Text>
    </NumberContainer>
  )
}

const HowStep = ({ number, text }) => {
  return (
    <Flex alignItems="center" mb="32px" maxWidth="500px">
      <NumberCount number={number} />
      <Text color="white" fontSize="18px">
        {text}
      </Text>
    </Flex>
  )
}

const RowColumnResponsive = styled(Flex)`
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`

const HeroContainer = styled(Flex)`
  background-color: #141c21;
  opacity: 1;
  background-size: 10px 10px;
  @media (max-width: 500px) {
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 0px 0px 100px 0px;
    height: 75vh;
    max-height: 500px;
  }
`

const BackgroundImageContainer = styled(Box)`
  @media (max-width: 500px) {
    z-index: 0;
    display: flex;
    justify-content: flex-end;
    margin-right: 00px;
  }
  .heroPhone {
    width: 40vw;

    height: 100%;
    @media (max-width: 1000px) {
      margin-top: 100px;
    }
    @media (max-width: 700px) {
      display: none;
    }
  }
`

const UseCaseContainer = styled(ResponsivePaddingFlex)`
  @media (max-width: 500px) {
    border-radius: 200px 0px 0px 0px;
  }
`

const MobileMenu = styled(MobileContainer)`
  height: 60px;
  background-color: ${colors.darkGrey};
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  position: sticky;
  top: 0;
  z-index: 1;
`

const IndexPage = () => {
  return (
    <Layout>
      <Helmet
        title={translate(`Receive SMS | Exclusive Free Temporary Phone numbers`, 'home.title', 'gb')}
        htmlAttributes={{
          lang: 'en'
        }}
        description={translate(
          `Receive SMS online with the web's only exclusive temp numbers. Always free, no signup required.`,
          'home.description',
          'gb'
        )}
        url="https://wereceivesms.com/"
      >
        <html lang="en" />
        <link rel="alternate" hrefLang="en" href={encodeURI(`https://wereceivesms.com/`)} />
        {activeCountries.map((iso2) => (
          <link rel="alternate" hrefLang={iso2} href={encodeURI(`https://wereceivesms.com/${iso2}/`)} />
        ))}
      </Helmet>
      <MobileMenu>
        <a href={`/`}>
          <Flex width="auto" alignItems="flex-end">
            <Image alt="home icon" mr="14px" width="30px" height="30px" src="/home.svg" />
          </Flex>
        </a>
        <Flex width="auto" alignItems="flex-end" onClick={() => document.getElementById('country-container').scrollIntoView()}>
          <Text bold mr="14px" color="white">
            {translate(`All numbers`, 'allnumbers', 'gb')}
          </Text>
          <Image alt="number icon" mt="1px" width="30px" height="30px" src="/number.svg" />
        </Flex>
      </MobileMenu>
      <HeroContainer
        width="100vw"
        height="80vh"
        justifyContent="space-between"
        // bg={colors.darkGrey}
        borderRadius="0px 0px 300px 0px"
        overflow="hidden"
        position="relative"
        maxHeight="700px"
      >
        <Shape1 />
        <Shape2 />
        <Shape3 />
        <Shape4 />
        <Left mt="200px">
          <Header>{translate('Receive SMS Online', `home.header`, 'gb')}</Header>
          <SubHeader>
            {translate('Receive SMS online with the webs only exclusive temporary numbers. Always free, no signup required.', `home.subheader`, 'gb')}
          </SubHeader>
        </Left>
        <BackgroundImageContainer position="relative">
          <StaticImage alt="mobile held in hand" className="heroPhone" src="../images/mobileHandphone.png" />
        </BackgroundImageContainer>
      </HeroContainer>
      <ResponsivePaddingFlex py="50px" flexDirection="column" zIndex={1} id="country-container">
        <CountryHeader>{translate('Select your country:', 'browsenumbercountry', 'gb')}</CountryHeader>
        <Grid>
          {Object.keys(telephones).map((otherCountry) => {
            return (
              <a key={otherCountry} href={`/${otherCountry}/`}>
                <CountryContainer>
                  <Flag iso2={countryToIso2[otherCountry]} />
                  <Counter count={telephones[otherCountry].length} />
                  <Text lineHeight="18px" px={2} textAlign="center" A>
                    {urlToString(otherCountry)}
                  </Text>
                </CountryContainer>
              </a>
            )
          })}
        </Grid>
      </ResponsivePaddingFlex>
      <Flex justifyContent="center">
        <RowColumnResponsive
          mx="16px"
          borderRadius="24px"
          bg="white"
          width="fit-content"
          p="24px"
          alignItems="center"
          justifyContent="center"
          mb="100px"
        >
          <Flex my="32px" mr="16px" width="100px" height="100px" borderRadius="100px" alignItems="center" justifyContent="center">
            <Image width="100px" height="100px" alt="question mark" src="/questionMark.svg" />
          </Flex>
          <Flex flexDirection="column" width="auto" maxWidth="600px">
            <H3>What are temporary phone numbers?</H3>
            <H4 mb="8px" fontWeight="300">
              Temporary phone numbers are also known as disposable phone numbers. They are virtual mobile numbers used for a specific period of time
              and then discarded. They are used to protect the user's privacy when making or receiving calls and texts. These numbers can be used to
              register for websites, receive OTPs (one-time passwords), and other services.
            </H4>
          </Flex>
        </RowColumnResponsive>
      </Flex>
      <ResponsivePaddingFlex py="50px" flexDirection="column" zIndex={1} id="country-container">
        <CountryHeader>Popular numbers:</CountryHeader>
        <NumberGrid>
          {Object.keys(telephones).map((key) => {
            return telephones[key].map((telephone, i) => {
              const numMessages = 5000 - Number(telephone.slice(-3)) * 5
              return (
                <a key={telephone} href={`/${countryTranslate(key, 'gb')}/${telephone}/`}>
                  <TelephoneContainer>
                    <Flex alignItems="center">
                      <Flag iso2={countryToIso2[key]} />
                      <Text width="100%" fontSize="26px" textAlign="right" mr={2}>
                        +{telephone}
                      </Text>
                      <OnlineIcon />
                    </Flex>
                    <Text>Messages received: {numMessages}</Text>
                  </TelephoneContainer>
                </a>
              )
            })
          })}
        </NumberGrid>
      </ResponsivePaddingFlex>

      <UseCaseContainer zIndex={1} flexDirection="column" alignItems="center" bg="white" pt="100px" pb="200px" borderRadius="300px 0px 0px 0px">
        <Text fontSize="16px" mb="8px">
          Why use us?
        </Text>
        <H4 fontSize="32px" lineHeight="40px" mb="40px" textAlign="center">
          Virtual Phone Numbers
        </H4>
        <RowColumnResponsive>
          <Flex flexDirection="column" alignItems="center" maxWidth="400px" mb="32px">
            <IconContainer bg="#E3FFEE">
              <Image alt="verify icon" width={56} height={56} src="/verify.svg" />
            </IconContainer>

            <Text bold fontSize="18px" mb="16px">
              Verification
            </Text>
            <Text lineHeight="28px" textAlign="center" px="24px">
              Test a new app or website without worrying about your primary phone number - receive notifications or promotional offers without giving
              out your real telephone. Bypass phone verification when signing up for online accounts.
            </Text>
          </Flex>
          <Flex flexDirection="column" alignItems="center" maxWidth="400px" mb="32px">
            <IconContainer bg="#ECF2FF">
              <Image alt="plane icon" width={56} height={56} src="/privacy.svg" />
            </IconContainer>

            <Text bold fontSize="18px" mb="16px" textAlign="center">
              Protect your privacy when signing up for online services.
            </Text>
            <Text lineHeight="28px" textAlign="center" px="24px">
              Avoid giving out your real phone number to strangers and prevent spam calls and SMS messages.
            </Text>
          </Flex>
          <Flex flexDirection="column" alignItems="center" maxWidth="400px" mb="32px">
            <IconContainer bg="#FFECEF">
              <Image alt="free icon" width={56} height={56} src="/nocost.svg" />
            </IconContainer>
            <Text bold fontSize="18px" mb="16px">
              Free Temporary Telephone Numbers
            </Text>
            <Text lineHeight="28px" textAlign="center" px="24px">
              All our virtual numbers are 100% free to use.
            </Text>
          </Flex>
        </RowColumnResponsive>
      </UseCaseContainer>
      <ResponsivePaddingFlex zIndex={1} py="50px" flexDirection="column" alignItems="center" bg={colors.darkGrey} pb="60px">
        <H4 fontSize="32px" mb="40px" color="white">
          How to get started
        </H4>
        <RowColumnResponsive>
          <Flex flexDirection="column" alignItems="center">
            <HowStep number={1} text="Find a virtual number in the list above" />
            <HowStep number={2} text="Send an SMS message to the number" />
            <HowStep number={3} text="Refresh messages until your message appears on screen" />
          </Flex>
          <StaticImage alt="desktop mockup" className="desktopMockup" width={600} src="../images/mobilePhoneHand2.png" />
        </RowColumnResponsive>
      </ResponsivePaddingFlex>
      <ResponsivePaddingFlex flexDirection="column">
        <H4 fontSize="32px" my="40px" textAlign="center">
          Reviews
        </H4>
        <RowColumnResponsive mb="24px">
          <Flex mb="24px" bg="white" mr="24px" mrm="0px" p="24px" borderRadius="16px">
            <Box mr="24px">
              <Box borderRadius="8px" overflow="hidden" maxHeight="60px">
                <StaticImage alt="review portrait" src="../images/reviews/1.jpg" width={60} height={60} />
              </Box>
            </Box>
            <Flex flexDirection="column">
              <Text fontSize="18px" fontWeight="500">
                Carter, US
              </Text>
              <Text>
                Recently tried this out, love it - worked very well. Instructions were clear and messages came through basically instantly. No
                horribly intrusive ads like other services on the internet.
              </Text>
            </Flex>
          </Flex>
          <Flex mb="24px" bg="white" mr="24px" mrm="0px" p="24px" borderRadius="16px">
            <Box mr="24px">
              <Box borderRadius="8px" overflow="hidden" maxHeight="60px">
                <StaticImage alt="review portrait" src="../images/reviews/3.jpg" width={60} height={60} />
              </Box>
            </Box>
            <Flex flexDirection="column">
              <Text fontSize="18px" fontWeight="500">
                Heather, UK
              </Text>
              <Text>First time using, very easy and able to open another chatgpt account with free credits. Perfect will use again</Text>
            </Flex>
          </Flex>
          <Flex mb="24px" bg="white" mr="24px" mrm="0px" p="24px" borderRadius="16px">
            <Box mr="24px">
              <Box borderRadius="8px" overflow="hidden" maxHeight="60px">
                <StaticImage alt="review portrait" src="../images/reviews/2.jpg" width={60} height={60} />
              </Box>
            </Box>
            <Flex flexDirection="column">
              <Text fontSize="18px" fontWeight="500">
                Fraiser, CA
              </Text>
              <Text>
                Big positive - most numbers are fresh, especially the Canadian ones. For this reason I use them over other temp number sites any day
                of the week.
              </Text>
            </Flex>
          </Flex>
        </RowColumnResponsive>
      </ResponsivePaddingFlex>
      <Footer iso2={'en'} />
    </Layout>
  )
}

export default IndexPage
